import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        props: { dashboardType: "default" },
        component: () => import("@/views/pages/Dashboard.vue")
      },
      {
        path: "/daily-summary",
        name: "dailySummary",
        props: { dashboardType: "default" },
        component: () => import("@/views/pages/DaySummary.vue")
      },
      {
        path: "/duration-stats",
        name: "duration-stats",
        props: { dashboardType: "default" },
        component: () => import("@/views/pages/DurationStats.vue")
      },
      {
        path: "/duration-stats/:stream(.*)",
        name: "duration-stats-stream",
        props: true,
        component: () => import("@/views/pages/DurationStats.vue")
      },
      {
        path: "/player-report",
        name: "player-stats",
        props: { serieField: "browser", filterField: "platform"},
        component: () => import("@/views/pages/BrowserStats.vue")
      },
      {
        path: "/player-report/:stream(.*)",
        name: "player-stats-stream",
        props: true,
        //props: { serieField: "browser", filterField: "platform"},
        component: () => import("@/views/pages/BrowserStats.vue")
      },
      {
        path: "/device-report",
        name: "device-stats",
        props: { serieField: "platform", filterField: "browser"},
        component: () => import("@/views/pages/BrowserStats.vue")
      },
      {
        path: "/device-report/:stream(.*)",
        name: "device-stats-stream",
        props: true,
        //props: { serieField: "platform", filterField: "browser"},
        component: () => import("@/views/pages/BrowserStats.vue")
      },
      {
        path: "/isp-stats",
        name: "isp-stats",
        component: () => import("@/views/pages/ISPStats.vue")
      },
      {
        path: "/isp-stats/:stream(.*)",
        name: "isp-stats-stream",
        props: true,
        component: () => import("@/views/pages/ISPStats.vue")
      },
      {
        path: "/geo-stats",
        name: "geo-stats",

        component: () => import("@/views/pages/GeoStats.vue")
      },
      {
        path: "/geo-stats/:stream(.*)",
        name: "geo-stats-stream",
        props: true,
        component: () => import("@/views/pages/GeoStats.vue")
      },
      {
        path: "/daily-stats",
        name: "daily-stats",
        component: () => import("@/views/pages/DailyStats.vue")
      },
      {
        path: "/deep-stats",
        name: "deepStats",
        component: () => import("@/views/pages/DeepStats.vue")
      },
      {
        path: "/daily-stats/:stream(.*)",
        props:true,
        name: "daily-stats-stream",
        component: () => import("@/views/pages/DailyStats.vue")
      },
      {
        path: "/switch-realm/:realm",
        name: "switch",
        redirect: to => {
          store.dispatch(Actions.SWITCH_REALM, to.params.realm);
          return { path: '/dashboard' };
        }

      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/pages/admin/Users.vue"),
        meta: {
          requiresAdminRole: true
        }
      },
      {
        path: "/realms",
        name: "realms",
        component: () => import("@/views/pages/admin/Realms.vue"),
        meta: {
          requiresAdminRole: true
        }
      },
      {
        path: "/servers",
        name: "servers",
        component: () => import("@/views/pages/admin/Servers.vue"),
        meta: {
          requiresAdminRole: true
        }
      }
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue")
  },
  /*{
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue")
  },*/
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue")
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    props: true,
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    path: "/reset-token/:token",
    name: "reset-token",
    props: true,
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  console.log("[router] ", to);
  if ( to.matched.some(record => record.meta.requiresAdminRole)) {
    if (store.getters.role != 'admin') {
      next({ name: "dashboard" });
    }else{
      next();
    }
  }else if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!store.getters.isUserAuthenticated) {
      next({ name: "sign-in" });
    } else {
      next(); // go to wherever I'm going
    }
  }else  {
    next(); // does not require auth, make sure to always call next()!
  }
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;

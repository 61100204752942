import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { array } from "yup";

export interface User {
  realms: Array<string>;
  company: string;
  lastname: string;
  firstname: string;
  email: string;
  role: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;

  user: User;
  isAuthenticated: boolean;
}
const USER_PROFILE = "user" as string;
const retrieveRealms = function() {
  const realms = localStorage.getItem("realms");
  if (realms) {
    return JSON.parse(realms);
  }
  return [];
};
@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = AuthModule.getUserFromToken() as User;
  users: User[] = [];
  realm: string = AuthModule.loadRealm() || "";
  realms: any[] = retrieveRealms();
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get currentRealm(): string {
    return this.realm;
  }
  get realmList(): Array<any> {
    const list =  this.realms.sort((a, b) => {
      console.log("compare", a.name, b.name);
      return a.name.localeCompare(  b.name);

    });

    return list;
  }
  /**
   * Get users
   * @returns []User
   */
  get userList(): Array<User> {
    return this.users;
  }
  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get role(): string {
    return this.user.role;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /*get permissionsParams(): any {
    const params = {};
    for (const i in this.user.permissions) {
      if (this.user.permissions[i] != "*") {
        params["u_" + i] = this.user.permissions[i];
      }
    }
    return params;
  }*/

  static parseJWT(token: string) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
  static loadRealm() {
    return localStorage.getItem("realm");
  }
  static getUserFromToken() {
    const token = JwtService.getToken();
    if (token) {
      const data = AuthModule.parseJWT(token);
      console.log("[AuthModule] token", data);
      const expiration = new Date();
      expiration.setTime(data.exp * 1000);
      console.log("[AuthModule] token expiration date", expiration);
      return data.user;
    }
    return null;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  /*const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };*/
  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    const decodedToken = AuthModule.parseJWT(data.access_token);
    console.log("[AuthModule] SET_AUTH ", decodedToken);
    this.user = decodedToken.user;

    if (
      this.realm &&
      this.user.role === "user" &&
      this.user.realms && this.user.realms.length > 0
    ) {
      if(!this.user.realms.includes(this.realm)){
        this.realm = this.user.realms[0];
        localStorage.setItem("realm",  this.realm);
      }
    }
    this.errors = [];
    JwtService.saveToken(data.access_token);
    ApiService.setHeader();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }
  @Mutation
  [Mutations.SET_REALMS](data) {
    localStorage.setItem("realms", JSON.stringify(data));
    this.realms = data;
  }

  @Mutation
  [Mutations.SET_REALM](realm) {
    console.log("Mutations.SET_REALM ", realm);
    localStorage.setItem("realm", realm);
    this.realm = realm;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    if (this.isAuthenticated) {
      return new Promise<void>(resolve => {
        ApiService.post("auth/logout", {}).finally(() => {
          console.log("[AuthModule].PURGE_AUTH");
          this.isAuthenticated = false;
          this.user = {} as User;
          window.localStorage.removeItem(USER_PROFILE);
          ApiService.removeAuthHeader();

          this.errors = [];
          JwtService.destroyToken();
          resolve();
        });
      });
    }
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          console.log("AuthModules.login", data);
          this.context.commit(Mutations.SET_AUTH, data);


          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_REALMS](credentials) {
    console.log("[AuthModule.LOAD_REALMS");
    return new Promise<void>((resolve, reject) => {
      ApiService.get("realms")
        .then(({ data }) => {
          console.log("AuthModules.LOAD_REALMS", data);
          this.context.commit(Mutations.SET_REALMS, data);
          if(!this.currentRealm){
            console.log("No one realm selected");
            this.context.commit(Mutations.SET_REALM, this.realms[0].name);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.SWITCH_REALM](realm) {
    console.log("Actions.SWITCH_REALM", realm);
    this.context.commit(Mutations.SET_REALM, realm);
  }

  @Action
  [Actions.GET_USERS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/users")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.PROFILE]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("user")
        .then(({ data }) => {
          console.log("PROFILE", data);
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot-password", payload)
        .then(({ data }) => {
          console.log(data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("reset-password", payload)
        .then(({ data }) => {
          console.log(data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      //this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

}

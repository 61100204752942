import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import contacts from "@/views/chat/contacts";
import { compileScript } from "@vue/compiler-sfc";
import store from "../../store";
import moment from "moment";
import router from "@/router";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;

    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    //console.log("API ---- "+ApiService.vueInstance.axios.defaults.baseURL);

    //ApiService.vueInstance.axios.defaults.headers.common["set-cookie"] = "XDEBUG_SESSION=PHPSTORM;";
    ApiService.setHeader();
    ApiService.vueInstance.axios.interceptors.response.use(
      res => {
        return res;
      },
      async err => {
        const originalConfig = err.config;
        console.log("[ApiService] interceptors", err);
        if (
          originalConfig.url !== "/auth/refresh" &&
          originalConfig.url !== "/auth/login" &&
          originalConfig.url !== "/auth/logout" &&
          err.response
        ) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
              const rs = await ApiService.vueInstance.axios.post(
                "/auth/refresh",
                {}
              );
              store.commit(Mutations.SET_AUTH, rs.data);
              originalConfig.headers[
                "Authorization"
              ] = `Bearer ${rs.data.access_token}`;
              return ApiService.vueInstance.axios.request(originalConfig);
            } catch (_error) {
              const resp = await store.dispatch(Actions.LOGOUT);
              router.push({ name: "sign-in" });
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    console.log("[APIService].setHeader");
    if (JwtService.getToken()) {
      console.log("[APIService].set JWT TOKEN");
      ApiService.vueInstance.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    }
  }

  public static removeAuthHeader() {
    delete ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ];
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(url: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${url}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
  public static getMainSeries(
    realm,
    from,
    to,
    params: AxiosRequestConfig
  ): Promise<any> {
    if (!params) {
      params = {};
    }
    if (!params.params) {
      params.params = {};
    }
    params.params.from = from;

    params.params.to = to; // moment.unix(to).utc().format("YYYY-MM-DD HH:mm:ss");
    console.log(
      "[ApiService] getMainSeries",
      realm,
      moment.unix(to).toISOString(),
      moment.unix(to).toISOString()
    );
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL + "stats/" + realm + "/main-series",
        params
      )
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }
  public static getSummaries(realm, from, to, params = {}): Promise<any> {
    if (!params) {
      params = {};
    }
    console.log("[ApiService] getSummaries", realm, from, to, {
      params: params
    });
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL +
          "stats/" +
          realm +
          "/summary/daily/" +
          from +
          "/" +
          to,
          {params: params}
      )
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }
  public static getDistinctStreams(realm, from, to, params = {}): Promise<any> {
    if (!params) {
      params = {};
    }
    params["from"] = from;
    params["to"] = to;
    console.log("[ApiService] getDistinctStreams", realm, params);
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL + "stats/" + realm + "/streams",
        { params: params }
      )
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }
  public static getDailySummary(realm, date, params = {}): Promise<any> {
    if (!params) {
      params = {};
    }
    console.log("[ApiService] getDailySummary", realm, date);
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL +
          "stats/" +
          realm +
          "/summary/daily/" +
          date,
        params
      )
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }
  public static getDailySummaryDates(realm, params = {}): Promise<any> {
    if (!params) {
      params = {};
    }

    console.log("[ApiService] getDailySummary", realm);
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL + "stats/" + realm + "/summary/dates",
        params
      )
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }

  public static getRealTime(
    realm,
    from,
    to,
    params: AxiosRequestConfig
  ): Promise<any> {
    if (!params) {
      params = {};
    }
    if (!params.params) {
      params.params = {};
    }
    params.params.from = from;

    params.params.to = to; // moment.unix(to).utc().format("YYYY-MM-DD HH:mm:ss");
    console.log(
      "[ApiService] getRealTime",
      realm,
      moment.unix(to).toISOString(),
      moment.unix(to).toISOString()
    );
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL + "stats/" + realm + "/realtime",
        params
      )
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }

  /**
   * @description get graph data

   * @param params: AxiosRequestConfig
   * @returns Promise<any>
   */
  public static fetchGraph(params: AxiosRequestConfig): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      ApiService.query(
        process.env.VUE_APP_API_BASE_URL + "stats/dashboard",
        params
      )
        .then(response => {
          console.log(response.data);
          const graphs = {
            selection: this.parseGraphItems(response.data.selected_range.data),
            range: response.data.selected_range.range,
            overall: this.parseGraphItems(response.data.overall)
          };
          resolve(graphs);
          return graphs;
        })
        .catch(error => {
          return reject(error);
        });
    });
    return promise;
  }
  public static parseGraphItems(graphsItems) {
    const graphs = {};

    for (const i in graphsItems) {
      const data = graphsItems[i];
      if (data.type == "value") {
        graphs[i] = data;
      } else if (data.type == "time-serie") {
        // data.data = ApiService.transformTimeSerie(data.data);
        graphs[i] = ApiService.transformTimeSerie(data);
      } else if (data.type == "serie") {
        const items: Array<any> = [];
        for (const j in data.data) {
          const item = data.data[j];
          items.push({ name: item.key, y: item.doc_count });
        }
        data.data = items;
        graphs[i] = data;
      } else if (data.type == "series") {
        /* const series: Array<any> = [];
        for (const j in data.data) {
          const serie = data.data[j];
          serie.data = ApiService.parseGraphItems(serie);
          series.push(serie);
        }*/

        const series = ApiService.parseGraphItems(data.data);
        const toArray: any = [];
        for (const j in series) {
          delete series[j].type;
          toArray.push(series[j]);
        }
        data.data = toArray;
        graphs[i] = data;
      }
    }
    console.log("graphs", graphs);
    return graphs;
  }
  public static transformTimeSerie(serie) {
    const items: Array<any> = [];
    for (const j in serie.data) {
      const item = serie.data[j];
      items.push([item.key, item.doc_count]);
    }
    serie.data = items;
    return serie;
  }
}

export default ApiService;

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
///import MockAdapter from "@/core/mock/MockService";

import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import {  Calendar, DatePicker } from "v-calendar";
import 'v-calendar/style.css';

//import 'v-calendar/dist/style.css';
import MomentTimeZone from "moment-timezone";

// import Highcharts, {GlobalOptions} from 'highcharts';
const timezone  = new Date().getTimezoneOffset();

import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import "/node_modules/flag-icons/css/flag-icons.min.css";


MomentTimeZone();
//import moment from "moment";
//import momentDurationFormatSetup from "moment-duration-format";
//momentDurationFormatSetup(moment);


import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
//import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
//import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

import "../node_modules/ag-grid-community/styles/ag-grid.css";
import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";

import "bootstrap";

const app = createApp(App);

app.component("Calendar", Calendar);
app.component("DatePicker", DatePicker);


app.use(store);
import router from "./router";
app.use(router);
app.use(ElementPlus);
import ApiService from "@/core/services/ApiService";
ApiService.init(app);

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
